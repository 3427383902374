<template>
  <div class="service-item-container" @click="jumpToService">
    <div
      class="service-item-background"
      :style="{ 'background-image': 'url(' + icons.contract + ')' }"
    ></div>
    <div class="service-detail-container">
      <span style="font-size: 14px; font-weight: bold">{{
        dataSource.name
      }}</span>
      <span class="service-detail-span">合同期限：{{ dataSource.year }}</span>
      <span class="service-detail-span"
        >起止时间：自{{ dataSource.start_time }}至{{
          dataSource.end_time
        }}</span
      >
    </div>
    <div class="service-item-state" v-if="dataSource.state == 1">待签署</div>
    <div class="service-item-invalid" v-else-if="dataSource.state == 3">
      已过期
    </div>
  </div>
</template>

<script>
import * as icon from "../../../common/icon";
export default {
  props: {
    dataSource: Object,
  },
  data() {
    return {
      icons: icon,
    };
  },
  methods: {
    jumpToService() {
      if (this.dataSource.count != 0) {
        window.location.href = this.dataSource.view_url;
      }else{
        this.$Message.error("请先开卡再进行签约");
      }
    },
  },
};
</script>

<style scoped>
.service-item-container {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  background-color: white;
  border-radius: 10px;
  padding: 20px 10px;
  margin: 0px 10px;
  margin-bottom: 10px;
  box-shadow: 0px 0px 5px #dddddd;
  overflow: hidden;
  cursor: pointer;
}
.service-item-background {
  height: 60px;
  width: 65px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.service-detail-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 10px;
}
.service-detail-span {
  font-size: 12px;
  font-weight: bold;
  color: #848484;
}
.service-item-state {
  position: absolute;
  top: 10px;
  right: -40px;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 120px;
  color: #fff;
  background-color: #43993e;
  transform: rotate(45deg);
}
.service-item-invalid {
  position: absolute;
  top: 10px;
  right: -40px;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 120px;
  color: #fff;
  background-color: gray;
  transform: rotate(45deg);
}
</style>