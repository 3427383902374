if (!global.eventMap) {
  global.eventMap = new Map();
}


exports.addEventListener = function (key, event) {
  if (!global.eventMap.has(key)) {
    global.eventMap.set(key, []);
  }

  let events = global.eventMap.get(key);
  events.push(event);
  global.eventMap.set(key, events);
}

exports.removeEventListener = function (key, event) {
  if (!global.eventMap.has(key)) {
    return;
  }

  let events = global.eventMap.get(key);
  for (let index in events) {
    if (events[index] === event) {
      events.splice(index, 1);
      return;
    }
  }
}

exports.triggerEvent = function (key, params) {
  if (!global.eventMap.has(key)) {
    return;
  }
  let events = global.eventMap.get(key);
  for (let event of events) {
    event(params);
  }
}